.CustomWarningModal__title {
  color: #FFF;
  font-weight: 500;
  font-size: 16px;
  text-transform: uppercase;
}
.CustomWarningModal__icon.CustomIcon {
  margin-right: 8px;
  color: #FFF;
  height: 20px;
  width: 20px;
}
.CustomWarningModal__bodyicon.CustomIcon {
  margin-right: 8px;
  color: #D38106;
  height: 20px;
  width: 20px;
}
.CustomWarningModal__footer {
  display: flex;
  flex-direction: column;
}
.CustomWarningModal__checkbox {
  margin-bottom: 8px;
}
.CustomWarningModal__checkbox > span:last-child {
  position: relative;
  bottom: 3px;
}
.CustomWarningModal__checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #D38106;
  border-color: #D38106;
}
.CustomWarningModal__checkbox .ant-checkbox-checked .ant-checkbox-inner:hover {
  border-color: #D38106;
}
.CustomWarningModal__checkboxText {
  color: #5A6462;
  font-size: 14px;
}
.CustomWarningModal .ant-modal-content {
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
}
.CustomWarningModal .ant-modal-header {
  background-color: #D38106;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.CustomWarningModal .ant-modal-footer {
  border: none;
  border-radius: 4px;
}
.CustomWarningModal .ant-btn {
  border: 1px solid #D38106;
  color: #D38106;
  border-radius: 4px;
  font-size: 14px;
}
.CustomWarningModal .ant-btn-primary {
  background-color: #D38106;
  color: #FFF;
}
