.notification_body_container_org_archive {
  display: flex;
  justify-content: space-between;
}
.notification_body_container_org_archive .AplanetIcon {
  margin-right: 5px;
}
.notification_body_container_org_archive_fileinfo {
  display: flex;
}
.notification_body_org_archive_fileinfo {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #23302E;
}
.notification_body_org_archive_download_button {
  color: #147F74 !important;
}
