.UserProfile .UserProfile-card {
  border-radius: 10px;
}
.UserProfile .UserProfile-label {
  color: #7B8989;
  font-size: 0.8em;
}
.UserProfile .UserProfile-value {
  font-weight: bold;
  font-size: 1em;
}
.UserProfile .UserProfile-name {
  font-size: 2.5em;
  font-weight: bold;
  color: #273941;
  line-height: 0.97;
}
.UserProfile .UserProfile-tabs {
  margin-top: 15px;
}
.UserProfile .UserProfile-select {
  height: 36px;
  width: 200px;
}
.UserProfile .UserProfile-information .UserProfile-information-divider {
  margin: 15px 0px;
}
.UserProfile .UserProfile-information .UserProfile-information-row-heading {
  font-weight: bold;
  font-size: 1em;
  margin-bottom: 24px;
}
.UserProfile .UserProfile-fileuploadwrap {
  position: relative;
  flex: 0 1;
  text-align: center;
  width: auto;
}
.UserProfile .UserProfile-fileupload {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
  z-index: 9;
}
.UserProfile .UserProfile-plussign {
  position: absolute;
  bottom: 0;
  right: calc(50% - 64px);
  background: #AAA;
  border-radius: 50%;
}
