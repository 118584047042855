.Logo-fileuploadwrap {
  margin-top: 20px;
  position: relative;
  flex: 0 1;
  text-align: center;
  width: auto;
}
.Logo-logo {
  max-height: 100px;
  max-width: 300px;
}
.Logo-fileupload {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
  z-index: 9;
}
