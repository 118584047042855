/* --- GENERAL --- */
* {
  font-family: Roboto, sans-serif;
}
body {
  background-color: #FCFCFC !important;
}
h1 {
  font-size: 65px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: -1.75px;
}
h2 {
  font-weight: bold;
  color: #273941;
}
.Content {
  display: flex;
  flex-direction: column;
  margin-top: 66px;
  padding: 0 40px 20px;
  background: #FCFCFC;
  z-index: 11;
}
.Content-noheader {
  display: flex;
  flex-direction: column;
  padding: 20px 40px;
  background: #FCFCFC;
}
.Whitespace {
  height: 50px;
}
.Whitespace20 {
  height: 20px;
}
.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: rgba(20, 127, 116, 0.15);
}
@media screen and (min-width: 1200px) {
  .Content {
    width: 1200px;
    margin: 66px auto 0;
  }
}
/* --- Header --- */
.ant-menu-item.ant-menu-item {
  font-size: 17px;
  width: auto;
}
.ant-layout-header.ant-layout-header {
  padding: 11px 12px 21px;
  width: auto;
  height: auto !important;
  position: relative !important;
}
.ant-layout-sider.ant-layout-sider-dark {
  z-index: 2;
}
