.AuthLayout {
  display: flex;
  height: 100%;
  align-items: stretch;
  min-height: 100vh;
}
.AuthLayout__container {
  display: flex;
  flex: 50%;
  min-width: 50%;
  flex-direction: column;
  padding: 0;
}
.AuthLayout__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 90px 90px 0 90px;
}
.AuthLayout__logo {
  height: 100%;
  overflow: hidden;
  max-width: 100%;
}
.AuthLayout__logo img {
  height: 100%;
  max-height: 80px;
  max-width: 100%;
  object-fit: contain;
}
.AuthLayout__content {
  display: flex;
  flex: 1;
  padding: 45px 90px 0 90px;
}
.AuthLayout__content > * {
  max-width: 100%;
}
.AuthLayout__cover {
  display: none;
  background-size: cover;
}
@media all and (min-width: 993px) {
  .AuthLayout__cover {
    display: flex;
    flex: 50%;
    flex-direction: column;
    justify-content: flex-end;
  }
}
.AuthLayout__quote {
  text-shadow: 0 1px 0 black;
  color: white;
  padding: 6em 4em 6em 12vw;
}
.AuthLayout__quote_intro {
  color: grey;
  font-size: 35px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  text-align: right;
}
.AuthLayout__quote_quote {
  font-size: 65px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -1.75px;
  text-align: right;
  display: block;
  margin-bottom: 15px;
}
.AuthLayout__quote_author {
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  text-align: right;
}
