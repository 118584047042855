/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.OrganizationTree {
  flex-shrink: 2;
  min-height: 0;
  display: flex;
  flex-direction: column;
}
.OrganizationTree .ant-input {
  position: relative;
  line-height: normal;
  padding: 6px 12px;
  border-radius: 8px;
  cursor: pointer;
  border: solid 2px rgba(123, 137, 137, 0.2);
}
.OrganizationTree .ant-input-affix-wrapper {
  height: 40px;
  margin-bottom: 16px;
  border: 1px solid #EBEAEA;
  border-radius: 4px;
}
.OrganizationTree .ant-tree {
  flex-shrink: 2;
  min-height: 0;
  overflow-y: auto;
}
.OrganizationTree .ant-tree-treenode {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  margin-top: 4px !important;
  align-items: center !important;
}
.OrganizationTree .ant-tree-node-content-wrapper {
  display: flex;
  align-items: center;
}
.OrganizationTree .ant-tree-iconEle {
  width: auto !important;
  height: auto !important;
  line-height: normal !important;
  margin-right: 5px;
}
.OrganizationTree-avatar {
  width: 32px;
  height: 32px;
}
.OrganizationTree-avatar img {
  object-fit: contain;
}
.ant-tree-treenode-disabled {
  background-color: #f5f5f5;
  opacity: 0.5;
}
.Org__card .ant-tree .ant-tree-node-content-wrapper {
  display: inline;
}
.Org__card .ant-tree .ant-tree-node-content-wrapper:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.Org__card .ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: #147F74;
  color: #FFF;
}
.OrganizationTree__treenode.OrganizationTree__treenode-leaf.ant-tree-treenode-switcher-open.ant-tree-treenode-selected {
  color: #FFF;
}
