.OrganizationLogo {
  background: transparent;
  float: left;
}
.OrganizationLogo img {
  object-fit: contain;
  display: block;
  max-width: 200px;
  max-height: 80px;
}
.OrganizationLogo .poweredBy {
  float: right;
  display: flex;
  align-items: center;
}
.OrganizationLogo .poweredBy span {
  float: left;
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #7b8989;
  margin-right: 4px;
}
.OrganizationLogo .poweredBy svg {
  float: left;
}
