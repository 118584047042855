.OrganizationProfile .CustomButton .anticon {
  width: 12px;
}
.OrganizationProfile .OrganizationProfile-card {
  border-radius: 10px;
}
.OrganizationProfile .OrganizationProfile-label {
  color: #7B8989;
  font-size: 0.8em;
}
.OrganizationProfile .OrganizationProfile-value {
  font-weight: bold;
  font-size: 1em;
}
.OrganizationProfile .OrganizationProfile-name {
  font-size: 2.5em;
  font-weight: bold;
  color: #273941;
  line-height: 0.97;
}
.OrganizationProfile .OrganizationProfile-score {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 44px;
  background-color: #ffcc00;
  border-radius: 8px;
  font-size: 1em;
  text-align: left;
  letter-spacing: -0.01em;
  font-weight: 500;
  bottom: -12px;
  left: -16px;
  margin-top: 5px;
}
.OrganizationProfile .OrganizationProfile-score img {
  height: 9px;
  width: 8px;
  margin-left: 2px;
  margin-bottom: 2px;
}
.OrganizationProfile .OrganizationProfile-tabs {
  margin-top: 15px;
}
.OrganizationProfile .OrganizationProfile-information .OrganizationProfile-information-row {
  margin-bottom: 15px;
}
.OrganizationProfile .OrganizationProfile-information .OrganizationProfile-information-row:last-of-type {
  margin-bottom: 5px;
}
.OrganizationProfile .OrganizationProfile-feature .OrganizationProfile-feature-flags {
  margin-top: 10px;
}
.OrganizationProfile .OrganizationProfile-feature .OrganizationProfile-feature-flags .OrganizationProfile-feature-flag .OrganizationProfile-feature-flag-allowed {
  color: #147F74;
}
.OrganizationProfile .OrganizationProfile-feature .OrganizationProfile-feature-flags .OrganizationProfile-feature-flag .OrganizationProfile-feature-flag-not-allowed {
  color: #D65050;
}
.OrganizationProfile .OrganizationProfile-admins-permission-logo {
  margin-left: 10px;
  margin-bottom: 5px;
}
.OrganizationProfile .OrganizationProfile-admins-status-active {
  font-weight: bold;
  color: #147F74;
}
.OrganizationProfile .OrganizationProfile-admins-status-blocked {
  font-weight: bold;
  color: #D65050;
}
.OrganizationProfile .OrganizationProfile-admins-status-invited {
  font-weight: bold;
  color: #FFC600;
}
.OrganizationProfile .OrganizationProfile-admins .typeIcon-inline {
  color: #FFC600;
  display: inline-block;
  margin-right: 3px;
}
.OrganizationProfile .OrganizationProfile-admins .typeIcon-img {
  max-height: 1em;
  margin-right: 3px;
}
.OrganizationProfile .OrganizationProfile-admins .typeIcon-img-crown {
  max-height: 0.8em;
  margin-bottom: 0.3em;
}
.OrganizationProfile .OrganizationProfile-admins-other-org {
  background-color: rgba(123, 137, 137, 0.1);
}
.OrganizationProfile .OrganizationProfile-admins-other-org:hover .ant-table-cell {
  background-color: rgba(123, 137, 137, 0.1);
}
.OrganizationProfile .OrganizationProfile-admins-other-org-info {
  margin-left: 10px;
}
.OrganizationProfile .OrganizationProfile-admins-header {
  margin-bottom: 10px;
}
.OrganizationProfile .OrganizationProfile-admins-header .ant-col-6 {
  display: flex;
  align-items: center;
}
.OrganizationProfile .OrganizationProfile-admins-filter-select {
  height: 36px;
  width: 100%;
}
.OrganizationProfile .OrganizationProfile-avatar img {
  object-fit: contain;
}
.OrganizationProfile-tree {
  height: 80vh;
  overflow: auto;
  background: #FFF;
}
.OrganizationProfile-admins-other-org-info-tooltip .ant-tooltip-inner {
  background-color: #4b5353;
}
.OrganizationProfile-delete-org:disabled {
  pointer-events: none;
}
