.AuthorizedLink-link {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: none;
  color: blue;
  display: inline;
  margin: 0;
  padding: 0;
}
