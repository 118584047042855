.PermissionLogo {
  background: transparent;
  width: 100%;
  position: relative;
  display: inline-block;
  height: 30px;
  width: 25px;
  flex: 0 1;
}
.PermissionLogo__bigicon {
  font-size: 21px;
}
.PermissionLogo__smallicon {
  background: rgba(255, 255, 255, 0.8);
  padding: 1px;
  border-radius: 50%;
  font-size: 12px;
  position: absolute;
  right: -3px;
  bottom: 0;
}
.PermissionLogo__validation {
  background: white;
  border-radius: 50%;
  font-size: 8px;
  position: absolute;
  right: -3px;
  bottom: -4px;
  border: 1px solid black;
  min-width: 15px;
  min-height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.PermissionLogo__size-sm {
  height: 20px;
  width: 15px;
}
.PermissionLogo__size-sm .PermissionLogo__bigicon {
  font-size: 16px;
}
.PermissionLogo__size-sm .PermissionLogo__smallicon {
  font-size: 8px;
}
