.AddAdmin .AddAdmin-label {
  color: #7B8989;
  font-size: 0.95em;
}
.AddAdmin .AddAdmin-permission-logo {
  margin-right: 10px;
}
.AddAdmin .AddAdmin-level-of-approval {
  margin-top: 20px;
}
.AddAdmin .AddAdmin-level-of-approval-select {
  height: 36px;
}
.Configuration-actions {
  display: flex;
  justify-content: flex-end;
}
.ManagePermissions .ManagePermissions-permission-logo {
  margin-right: 10px;
}
.ManagePermissions-level-of-approval .ant-form-item {
  flex-direction: column;
}
.ManagePermissions-level-of-approval .ant-form-item-label {
  text-align: left;
}
.CreateOrganization {
  width: 100%;
}
.Option {
  justify-content: space-between;
}
.Option-item {
  flex-grow: 1;
}
.Option-item .schema-options {
  display: flex;
  flex-direction: column;
}
.Option-item .schema-options-label {
  margin-left: 23px;
}
.Option-item .schema-options .ant-radio-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.Option-item .schema-options .ant-radio-wrapper span {
  display: flex;
}
.Option-item .schema-options .ant-radio-wrapper span .color {
  min-width: 20px;
  min-height: 20px;
  border-radius: 3px;
  margin-right: 4px;
}
.LabelList-values {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.LabelList-values button {
  margin-top: 10px;
}
.LabelList-values input {
  margin-top: 10px;
  width: 200px;
}
.LabelList-values .ant-tag {
  margin-top: 10px;
  margin-right: 10px;
  font-size: large;
  padding: 10px;
}
.LabelList-title {
  display: flex;
  justify-content: space-between;
}
.LabelList-infoList {
  display: flex;
}
.LabelList-info {
  height: 26px;
  padding: 0 10px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-right: 10px;
  color: white;
}
.SubOption {
  justify-content: space-between;
}
.OrganizationProfile-value .OrganizationProfile-tag.OrganizationProfile-tag.OrganizationProfile-tag {
  margin-right: 10px;
  background-color: #147F74;
  color: white;
}
.OrganizationProfile-value .OrganizationProfile-tag.OrganizationProfile-tag.OrganizationProfile-tag:last-child {
  margin-right: 0;
}
.OrganizationProfile-admins-actions-modal {
  font-family: Roboto;
  max-width: 646px;
}
.OrganizationProfile-admins-actions-modal .ant-modal-content {
  border-radius: 0px !important;
}
.OrganizationProfile-admins-actions-modal .ant-modal-content .ant-modal-header {
  box-shadow: none;
}
.OrganizationProfile-admins-actions-modal .ant-modal-content .ant-modal-header .ant-modal-title {
  font-size: 16px;
  display: flex;
  align-items: center;
}
.OrganizationProfile-admins-actions-modal .ant-modal-content .ant-modal-header .ant-modal-title svg {
  margin-right: 10px;
}
.OrganizationProfile-admins-actions-modal .ant-modal-content .ant-modal-body {
  color: #23302E;
}
.OrganizationProfile-admins-actions-modal .ant-modal-content .ant-modal-footer button:first-child {
  color: #C13838;
  border: 1px solid #C13838;
}
.OrganizationProfile-admins-actions-modal .ant-modal-content .ant-checkbox-wrapper {
  margin-top: 32px;
  color: #147F74;
}
.OrganizationProfile-admins-actions-modal .ant-modal-content .disabled {
  color: #919897;
}
.CreateEditOrganizationForm__warning-messsage {
  color: #FFC600;
}
