.SsoLayout {
  display: flex;
  height: 100vh;
}
.SsoLayout__container {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0;
}
.SsoLayout__footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 2vmin;
}
.SsoLayout__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 5vmin;
  max-height: calc(100% - 60px);
}
