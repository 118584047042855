.OrganizationSelector__col {
  margin-bottom: 10px;
}
.OrganizationSelector-empty.ant-empty.ant-empty {
  margin: auto;
}
.OrganizationSelector-Header {
  display: flex;
  justify-content: flex-end;
  position: relative;
  line-height: normal;
  padding: 6px 12px;
  height: 52px;
  border-radius: 8px;
  cursor: pointer;
}
.OrganizationSelector-Header:hover {
  background: rgba(123, 137, 137, 0.07);
}
.OrganizationSelector-Header.ant-dropdown-open {
  background: rgba(123, 137, 137, 0.15);
}
.OrganizationSelector-Header .OrganizationSelector-avatar {
  width: 32px;
  height: 32px;
}
.OrganizationSelector-Header .OrganizationSelector-profileName {
  margin-left: 8px;
  font-size: 15px;
  font-weight: 500;
  color: #273941;
  margin-right: 22px;
  display: inline;
  padding: 11px 0;
}
.OrganizationSelector-Header .OrganizationSelector-chevron {
  padding: 17px 0;
}
