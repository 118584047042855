.PasswordForm__heading {
  margin-bottom: 0.2em;
}
.PasswordForm__subheading {
  margin-bottom: 40px;
  font-weight: 300;
  color: #273941;
  font-size: 31px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.PasswordForm__recover_password_line {
  height: 2em;
  padding-bottom: 4px;
  text-align: right;
}
.PasswordForm__recover_password_error {
  color: #D65050;
}
.PasswordForm__recover_password_sent {
  color: #147F74;
}
