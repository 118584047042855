.PhoneInput {
  border: 1px solid #E5E8E8;
}
.PhoneInput select {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  border: 0;
  opacity: 0;
  cursor: pointer;
}
.PhoneInput img,
.PhoneInput svg {
  float: left;
}
.PhoneInput .react-phone-number-input {
  padding-right: 0;
  width: 100%;
}
.PhoneInput .react-phone-number-input__row {
  width: 100%;
  height: 40px;
}
.PhoneInput .react-phone-number-input__row .react-phone-number-input__country--native {
  width: 45px;
  justify-content: center;
}
.PhoneInput .react-phone-number-input__row .PhoneInput-input {
  border: none;
  box-shadow: none;
  border-left: 2px solid #d9d9d9;
  padding-left: 6px;
}
