.CustomButton {
  border-radius: 6px;
  border: solid 2px rgba(123, 137, 137, 0.2);
  background-color: #FFF;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.23px;
  color: #273941;
  padding: 0 12px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  /*&:hover{
    background-color: @secondary-text-color-fade-twenty;
    border-color: transparent;
    color: @primary-text-color;
  }*/
}
.CustomButton img,
.CustomButton svg {
  margin-right: 12px;
}
.CustomButton.ant-btn-primary {
  background: #147F74;
  color: white;
}
.CustomButton.ant-btn-primary[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.CustomButton.ant-btn-primary:hover {
  background-color: #1bab9c;
}
.CustomButton.ant-btn-primary[disabled]:hover {
  background-color: #f5f5f5;
}
.CustomButton.ant-btn-dark {
  background: transparent;
  color: white;
}
.CustomButton.ant-btn-dark:hover {
  background-color: rgba(123, 137, 137, 0.2);
}
.CustomButton.ant-btn-dangerous {
  color: #C13838;
  background: #FFF;
  border-color: #C13838;
}
.CustomButton.ant-btn-dangerous:disabled {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
}
.CustomButton.ant-btn-sm {
  height: 24px;
}
.CustomButton__tooltip {
  font-size: 12px;
}
.CustomButton__tooltip .ant-tooltip-arrow-content {
  background: #147F74;
  opacity: 0.9;
}
.CustomButton__tooltip .ant-tooltip-arrow-content::before {
  background: #147F74;
  opacity: 0.9;
}
.CustomButton__tooltip .ant-tooltip-inner {
  background: #147F74;
  opacity: 0.9;
}
.CustomButton-icon img,
.CustomButton-icon svg {
  margin-right: 1px;
}
