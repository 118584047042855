.NewCustomSelect {
  position: relative;
  width: 100%;
}
.NewCustomSelect .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border: none;
}
.NewCustomSelect .NewCustomSelect-button {
  width: 100%;
  height: 46px;
  border-radius: 6px;
  border: solid 2px rgba(123, 137, 137, 0.2);
  background-color: #FFF;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.23px;
  color: #273941;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.NewCustomSelect .NewCustomSelect-button > .ant-select-selection {
  background: transparent;
  padding: 0 12px;
  width: 100%;
  border: none;
}
.NewCustomSelect .NewCustomSelect-button > .ant-select-selection .ant-select-selection-selected-value {
  width: calc(100% - 18px);
  overflow: hidden;
  text-overflow: ellipsis;
}
.NewCustomSelect .NewCustomSelect-button.NewCustomSelect-disabled {
  background-color: #f5f5f5;
}
.NewCustomSelect .NewCustomSelect-button.NewCustomSelect-open {
  background-color: #147F74;
  border-color: #147F74;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.23px;
}
.NewCustomSelect .NewCustomSelect-button.NewCustomSelect-open.highlighted {
  color: #FFF;
}
.NewCustomSelect .NewCustomSelect-button.NewCustomSelect-open.highlighted .ant-select-selector {
  border: none;
  background: #147F74;
}
.NewCustomSelect .NewCustomSelect-button.NewCustomSelect-open.highlighted .ant-select-selection,
.NewCustomSelect .NewCustomSelect-button.NewCustomSelect-open.highlighted .ant-select-selection-selected-value {
  background: #147F74;
}
.NewCustomSelect .NewCustomSelect-button.NewCustomSelect-open.highlighted .fillColor {
  fill: #FFF;
}
.NewCustomSelect .NewCustomSelect-button.NewCustomSelect-open.highlighted .ant-select-selection__placeholder,
.NewCustomSelect .NewCustomSelect-button.NewCustomSelect-open.highlighted .ant-select-arrow {
  color: #FFF;
}
.NewCustomSelect .NewCustomSelect-menu {
  border-radius: 6px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
  border: solid 1px rgba(123, 137, 137, 0.1);
  background-color: #FFF;
  position: absolute;
  z-index: 2;
  margin-top: 3px;
  display: flex;
  flex-direction: column;
  width: 100%;
  right: 0px;
}
.NewCustomSelect .NewCustomSelect-menu .NewCustomSelect-option {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 13px 20px;
}
.NewCustomSelect .NewCustomSelect-menu .NewCustomSelect-option:hover {
  background: rgba(123, 137, 137, 0.1);
}
.NewCustomSelect .NewCustomSelect-menu .NewCustomSelect-option .NewCustomSelect-option-name {
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.07px;
  color: #273941;
  white-space: nowrap;
}
