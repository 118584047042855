.main-layout-menu {
  display: flex;
  flex-direction: column;
  padding-right: 20px;
  gap: 10px;
}
.main-layout-lateral-menu {
  padding: 32px 12px;
  gap: 40px;
  display: flex;
  flex-direction: column;
  min-width: 25%;
  max-width: 25%;
  max-height: 100vh;
}
.sider {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 100vh;
  max-height: 100vh;
  z-index: 1000;
}
.sider .ant-menu-item {
  margin-left: 0;
  margin-right: 0;
}
.sider .ant-layout-sider-children {
  min-height: 100vh;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
}
.sider .ant-layout-sider-children .icon {
  margin-bottom: 13px;
  display: flex;
  justify-content: center;
  padding: 7px 0;
  cursor: pointer;
}
.sider .ant-layout-sider-children .icon.selected {
  background: #DBEAE8;
  border-radius: 4px;
  color: #117065;
}
.sider__logo-wrapper {
  min-height: 100px;
}
.sider__trigger--collapsed,
.sider__trigger--not-collapsed {
  margin-top: 10px;
}
.sider__trigger--not-collapsed {
  margin-right: 26px;
}
.sider__logo-full {
  margin-top: 30px;
  margin-bottom: 40px;
}
.sider__logo-small {
  margin-top: 28px;
  margin-bottom: 45px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.sider__logo-small > img {
  width: 32px;
  height: 32px;
}
.sider.ant-layout-sider-collapsed .icon {
  padding-right: 0;
}
.AvatarMenuPopOver .ant-popover-arrow {
  display: none;
}
