.LanguageSelector {
  width: 90%;
  margin-right: 1em;
  border: none;
}
.LanguageSelector > *.ant-select-selection {
  border: none;
  box-shadow: none;
}
.LanguageSelector .ant-select-selection .ant-select-selection__rendered {
  display: inline-block;
  color: #7B8989;
  margin-right: 24px;
}
.LanguageSelector .ant-select-selection .ant-select-arrow {
  position: static;
}
@media all and (min-width: 769px) {
  .LanguageSelector {
    width: 100%;
  }
}
