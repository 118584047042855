/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.AppHeader__right {
  flex: 1;
}
.AppHeader__left {
  display: flex;
  gap: 44px;
}
.AppHeader__dropdown {
  max-width: 350px;
  border: solid 1px rgba(123, 137, 137, 0.1);
  border-radius: 6px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.07);
}
.AppHeader__dropdown .ant-menu-submenu-arrow {
  visibility: hidden;
}
.AppHeader__selected {
  background-color: #b4bfbc;
  position: relative;
}
.AppHeader__selected:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border-right: 3px solid #147F74;
}
.AppHeader input.OrganizationSelector {
  height: 46px;
  background: #147F74;
  color: #fff;
}
.AppHeader-appTitle {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #5A6462;
}
.AppHeader-productLinks .AppHeader-linkToApp {
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.1px;
  color: #147F74;
  margin-top: 1px;
  cursor: pointer;
  padding: 8px;
  border: 1px solid #EBEAEA;
  border-radius: 24px;
  background-color: transparent;
}
.AppHeader-avatarMenu,
.AppHeader-suborganizationMenu {
  position: relative;
  line-height: normal;
  padding: 6px 12px;
  height: 52px;
  border-radius: 8px;
  cursor: pointer;
}
.AppHeader-avatarMenu:hover,
.AppHeader-suborganizationMenu:hover {
  background: rgba(123, 137, 137, 0.07);
}
.AppHeader-avatarMenu.ant-dropdown-open,
.AppHeader-suborganizationMenu.ant-dropdown-open {
  background: rgba(123, 137, 137, 0.15);
}
.AppHeader-avatarMenu .AppHeader-avatar,
.AppHeader-suborganizationMenu .AppHeader-avatar {
  width: 32px;
  height: 32px;
}
.AppHeader-avatarMenu .AppHeader-profileName,
.AppHeader-suborganizationMenu .AppHeader-profileName {
  margin-left: 8px;
  font-size: 15px;
  font-weight: 500;
  color: #273941;
  margin-right: 22px;
  display: inline;
  padding: 11px 0;
}
.AppHeader-avatarMenu .AppHeader-chevron,
.AppHeader-suborganizationMenu .AppHeader-chevron {
  padding: 17px 0;
}
.AppHeader-suborganizationMenu::after {
  content: '';
  height: 60%;
  width: 1px;
  background-color: #E5E8E8;
  position: absolute;
  top: 20%;
  right: -8px;
  border-radius: 50%;
}
.NotificationCenter__container {
  display: flex;
  justify-content: center;
}
