.SearchBox .ant-input {
  border-radius: 6px;
  border-top: solid 2px rgba(123, 137, 137, 0.2);
  border-left: solid 2px rgba(123, 137, 137, 0.2);
  border-bottom: solid 2px rgba(123, 137, 137, 0.2);
  background-color: #FFF;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  height: 38px;
}
.SearchBox .ant-input:focus {
  border-color: #147F74 !important;
  box-shadow: none !important;
}
.SearchBox .ant-input:hover {
  border-color: rgba(123, 137, 137, 0.4);
  box-shadow: none;
}
.SearchBox .ant-input-group-addon .ant-btn {
  height: 46px;
  width: 62px;
}
.SearchBox .ant-input-group-addon .ant-btn:hover .atn-btn {
  background: #1bab9c;
  border-color: #1bab9c;
}
