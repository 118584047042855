.CustomSelect {
  height: 46px;
  box-shadow: none;
  border-radius: 6px;
  border: solid 2px rgba(123, 137, 137, 0.2);
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;
}
.CustomSelect:hover {
  border: solid 2px rgba(123, 137, 137, 0.4) !important;
  box-shadow: none;
}
.CustomSelect:focus {
  border: solid 2px #147F74 !important;
  box-shadow: none;
}
