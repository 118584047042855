.SetInitialPassword__heading {
  margin-bottom: 0.2em;
}
.SetInitialPassword__subheading {
  margin-bottom: 40px;
  font-weight: 300;
  color: #273941;
  font-size: 31px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.SetInitialPassword__link {
  margin: 10px 0 0;
}
.SetInitialPassword__ButtonWrapper .ant-form-item-control-input-content {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.SetInitialPassword__ButtonWrapper .ant-form-item-control-input-content .CustomButton {
  max-width: 100px;
}
