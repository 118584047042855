.SignedOutFooter {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-self: self-end;
  width: 100%;
  background: transparent;
  padding-right: 75px;
  padding-left: 64px;
}
@media all and (min-width: 769px) {
  .SignedOutFooter {
    flex-wrap: nowrap;
    justify-content: space-between;
  }
}
.SignedOutFooter__block {
  flex: 1;
  display: block;
  justify-content: flex-end;
  align-self: flex-end;
}
@media all and (min-width: 769px) {
  .SignedOutFooter__block {
    display: flex;
    align-items: center;
  }
}
.SignedOutFooter__block > div {
  margin: 5px 15px;
}
.SignedOutFooter__link {
  color: #7B8989;
}
