.Loading-back {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #FCFCFC;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Loading-back img {
  width: 30%;
}
