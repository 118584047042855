.Popup_container {
  flex-grow: 1;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07), 0 5px 10px 0 rgba(0, 0, 0, 0.07);
  border: solid 1px rgba(123, 137, 137, 0.1);
  background-color: #FFF;
  padding: 4px 10px;
}
.Popup_container.unread {
  border-left: solid 4px;
  border-left-color: #D38106;
}
.Popup__divider {
  margin: 12px 0;
}
.Popup__header__notification__date {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  margin-bottom: 10px;
  /* identical to box height, or 22px */
  /* TEXT/BLACK */
  color: #23302E;
}
.Popup__header__notification__name {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  margin-bottom: 10px;
  /* identical to box height, or 22px */
  /* TEXT/BLACK */
  color: #23302E;
}
.Popup__header__avatar {
  display: flex;
}
.Popup__avatar {
  width: 32px;
  height: 32px;
}
.Popup__member__info {
  display: flex;
  flex-direction: column;
}
.Popup__name {
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.07px;
  color: #273941;
  margin-left: 11px;
}
.Popup__Footer__Navigation {
  align-self: center;
}
.Popup__Footer__NavigationLink {
  margin-right: 5px;
}
.Popup__Footer__Buttons {
  display: flex;
  justify-content: flex-end;
}
.Popup__Footer__Buttons__delete {
  border: none !important;
}
.Popup__Footer__Buttons__delete .AplanetIcon {
  color: #C13838 !important;
}
.Popup__Footer__Buttons__read {
  border: none !important;
}
.Popup__Footer__Buttons__read .AplanetIcon {
  color: #147F74 !important;
}
