.SetPassword__heading {
  margin-bottom: 0.2em;
}
.SetPassword__heading .PasswordPolicyInfo {
  font-size: 0.28em;
  margin-left: 10px;
}
.SetPassword__subheading {
  margin-bottom: 40px;
  font-weight: 300;
  color: #273941;
  font-size: 31px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
