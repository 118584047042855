.Notification_container {
  flex-grow: 1;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07), 0 5px 10px 0 rgba(0, 0, 0, 0.07);
  border: solid 1px rgba(123, 137, 137, 0.1);
  background-color: #FFF;
  padding: 4px 10px;
}
.Notification_container.unread {
  border-left: solid 4px;
  border-left-color: #D38106;
}
.Notification__divider {
  margin: 12px 0;
}
.Notification__header {
  padding: 5px;
}
.Notification__header__notification__date {
  align-self: center;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  /* identical to box height, or 22px */
  /* TEXT/MEDIUM */
  color: #919897;
}
.Notification__header__notification__name {
  align-self: center;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 150%;
  /* identical to box height, or 22px */
  /* TEXT/BLACK */
  color: #23302E;
}
.Notification__header__avatar {
  display: flex;
}
.Notification__avatar {
  width: 32px;
  height: 32px;
}
.Notification__member__info {
  display: flex;
  flex-direction: column;
}
.Notification__name {
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.07px;
  color: #273941;
  margin-left: 11px;
}
.Notification__Footer__Navigation {
  align-self: center;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #147F74;
}
.Notification__Footer__NavigationLink {
  margin-right: 5px;
}
.Notification__Footer__Buttons {
  display: flex;
  justify-content: flex-end;
}
.Notification__Footer__Buttons__delete {
  border: none !important;
}
.Notification__Footer__Buttons__delete .AplanetIcon {
  color: #C13838 !important;
}
.Notification__Footer__Buttons__read {
  border: none !important;
}
.Notification__Footer__Buttons__read .AplanetIcon {
  color: #147F74 !important;
}
.Notification__Footer__Buttons__unread {
  border: none !important;
}
.Notification__Footer__Buttons__unread .AplanetIcon {
  color: #D38106 !important;
}
