.SsoSuccess__layout {
  background-color: #FCFCFC;
  color: #147F74;
}
.SsoSuccess__layout h1 {
  color: #147F74;
}
.SsoSuccess__layout .SsoLayout__logo {
  filter: brightness(0%) opacity(65%);
}
.SsoSuccess__icon {
  flex: 1;
  width: auto;
  height: 70vmin;
}
.SsoSuccess__icon svg {
  height: 100%;
  width: 100%;
  padding: 5vmin;
}
