.notification_body_container_bulk_manage_export .AplanetIcon {
  margin-right: 5px;
}
.notification_body_container_bulk_manage_export_fileinfo {
  display: flex;
}
.notification_body_bulk_manage_export_fileinfo {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #23302E;
}
.notification_body_bulk_manage_export_download {
  text-align: right;
}
.notification_body_bulk_manage_export_download_button {
  color: #147F74 !important;
}
