.notificationCenter_icon {
  background: #FFFFFF !important;
  /* Main_shadow */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15) !important;
  border-radius: 4px !important;
  color: #D38106 !important;
}
.notificationCenter_badge .ant-badge-count {
  background-color: #D38106;
  font-size: 10px;
  font-weight: 500;
  font-family: 'Roboto';
  font-style: normal;
  color: #FFF;
}
.notificationCenter_drawer .ant-drawer-body {
  padding: 0;
}
.notificationCenter__filter_container {
  display: flex;
  padding: 8px 12px !important;
}
.notificationCenter__filter_item {
  cursor: pointer;
  border-radius: 4px;
  padding: 2px 6px;
  font-weight: 500;
  font-size: 12px;
  background-color: #EBEAEA;
  color: #5A6462;
  margin-right: 10px;
}
.notificationCenter__filter_item .AplanetIcon {
  margin-right: 5px;
}
.notificationCenter__filter_item.active {
  background-color: #F8ECDA;
  color: #D38106;
}
.notificationCenter__bulk_read {
  border: none !important;
  color: #147F74 !important;
}
.notificationCenter__bulk_read .AplanetIcon {
  color: #147F74 !important;
}
.notificationCenter__bulk_unread {
  border: none !important;
  color: #D38106 !important;
}
.notificationCenter__bulk_unread .AplanetIcon {
  color: #D38106 !important;
}
.notificationCenter_list_item {
  display: flex;
  padding: 8px 12px !important;
}
.notificationCenter > .ant-drawer-content-wrapper > .ant-drawer-content > .ant-drawer-wrapper-body > .ant-drawer-body {
  padding: 24px 0;
}
