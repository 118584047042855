.OrganizationSummaryCard {
  display: flex;
  flex-direction: column;
  padding: 10px;
  background: #FFF;
  border-radius: 8px;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15);
  height: 100%;
  cursor: pointer;
}
.OrganizationSummaryCard__logo {
  overflow: hidden;
  align-self: center;
  height: 100px;
  width: 100px;
  margin: 10px;
  object-fit: contain;
}
.OrganizationSummaryCard__logo img {
  width: 100%;
  height: 100%;
}
.OrganizationSummaryCard__name {
  text-align: center;
}
