.AplanetIcon__tooltip .ant-tooltip-arrow-content {
  background: #147F74;
  opacity: 0.9;
}
.AplanetIcon__tooltip .ant-tooltip-arrow-content::before {
  background: #147F74;
  opacity: 0.9;
}
.AplanetIcon__tooltip .ant-tooltip-inner {
  background: #147F74;
  opacity: 0.9;
}
